import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import IssueOne from "../assets/issues/No-Scene-zine-issue-1.pdf";
import IssueTwo from "../assets/issues/no-scene-issue-2.pdf";
import IssueThree from "../assets/issues/No-Scene-3-Site.pdf";

export default class Media extends React.Component {

    render() {
        return (
            <Layout>
                <SEO title="Media" />

                <div className="content">
                    <h1 style={{color: 'black', fontSize: '50px'}}>Media</h1>

                    <a href={IssueOne}>Download: Issue 1</a><br/>
                    <a href={IssueTwo}>Download: Issue 2</a><br/>
                    <a href={IssueThree}>Download: Issue 3</a>
                </div>
            </Layout>
        );
    }
}
